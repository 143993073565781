const declareSystemColors = () => {
    document.documentElement.style.setProperty("--system-red", '#E80003');
    document.documentElement.style.setProperty("--system-blue", '#3560DA');
    document.documentElement.style.setProperty("--system-green", '#2FAC00');
    document.documentElement.style.setProperty("--system-green2", '#049338');
    document.documentElement.style.setProperty("--system-orange", '#EE8600');
    document.documentElement.style.setProperty("--system-yellow", '#F6CA1C');
    document.documentElement.style.setProperty("--system-grey-5", '#242426');
    document.documentElement.style.setProperty("--system-grey-4", '#5C5C5F');
    document.documentElement.style.setProperty("--system-grey-3", '#8E8E93');
    document.documentElement.style.setProperty("--system-grey-2", '#B2B2B6');
    document.documentElement.style.setProperty("--system-grey-1", '#D8D8DC');
    document.documentElement.style.setProperty("--system-grey-0", '#F4F4F9');
}

exports.onClientEntry = () => {
    declareSystemColors();
}